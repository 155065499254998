// See https://fontawesome.com/how-to-use/on-the-web/using-with/turbolinks
import { config, library, dom } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"

config.mutateApproach = 'sync'

import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
library.add (fas, far)
dom.watch();
