/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

var Turbolinks = require("turbolinks")
Turbolinks.start()

require('bootstrap')
import 'jquery-ujs'
import 'popper.js'
import ClipboardJS from 'clipboard'
// require('garlicjs')

document.addEventListener('turbolinks:load', function() {
  new ClipboardJS('.clipboard')
  $('[data-toggle="popover"]').popover()
})

import '../src/cocoon'
import '../src/fontawesome.js'
import '../src/maps.js'
// import '../src/scholarship_application.js'
import '../style/application.scss'

// import Chartkick from "chartkick"
// import Chart from "chart.js"
console.log('GGAP ready')
