import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css' // Re-uses images from ~leaflet package
import * as L from 'leaflet'
import 'leaflet-defaulticon-compatibility'
import 'pouchdb'
import 'leaflet.tilelayer.pouchdbcached'

/*
 * Turns DOM elements with a '.map' class into Leaflet maps.
 * The DOM element must have a unique ID for this to work.
 * The map parameters are defined in the element's data attributes:
 * data-lat, data-lon, data-zoom: Position of the map center and initial zoom.
 * data-markers-url: Optional URL to fetch marker coordinates. These must be
 * JSON records with a 'longitude' and 'latitude' field. If data-markers-url
 * is not present, the center of the map will be marked with a pin.
 */
document.addEventListener('turbolinks:load', function() {
  /*
    * Creates a new map in a DOM element.
    * Parameters:
    * id      - unique element id of the DOM element
    * lat     - latitude of map center
    * lon     - longitude of map center
    * zoom    - initial zoom
    * markers - an array of [lat, lon] arrays for markers
    */
  var createMap = function(id, lat, lon, zoom, data) {
    var attribution, icon, m, obj, pin, template, url, _i, _len, _results;
    m = L.map(id, {
      zoomControl: false,
      useCache: true,
      crossOrigin: true,
      cacheMaxAge: 100  * 24 * 60 * 60 * 1000 // 100 days
    }).setView([lat, lon], zoom);
    template = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    // attribution = 'Map data &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>';
    L.tileLayer(template, {
      attribution: attribution,
      maxZoom: 18
    }).addTo(m);
    for (_i = 0, _len = data.length; _i < _len; _i++) {
      obj = data[_i];
      if (obj["latitude"] && obj["longitude"]) {
        pin = L.marker([obj["latitude"], obj["longitude"]]);
        pin.addTo(m);
        if (obj["url"]) {
          url = obj["url"].split(".json").join("");
          pin.bindPopup("<a href=" + url + ">" + obj['description'] + "</a>");
        } else {
          if (obj["description"]) {
            pin.bindPopup(obj['description']);
          }
        }
      }
    }
  }

  return $('.map').each(function(index) {
    var id, lat, lon, markers_url, zoom;
    id = this.id;
    if (id === void 0) {
      throw 'maps.js: Map element must have a DOM id!';
    }
    lat = $(this).data('lat');
    lon = $(this).data('lon');
    zoom = $(this).data('zoom') || 6;
    markers_url = $(this).data('markers-url');
    if (markers_url) {
      return $.ajax(markers_url, {
        dataType: "json",
        success: function(data, status, xhr) {
          createMap(id, lat, lon, zoom, data);
        },
        error: function(error) {
          // class must be added with leading space!
          this.className += ' ajax-error';
        }
      });
    } else {
      createMap(id, lat, lon, zoom, [
        {
          "latitude": lat,
          "longitude": lon
        }
      ]);
    }
  })
})
